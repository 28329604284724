<template lang="pug">
  v-container
    v-row
      v-col
        span(class="title accent--text") Autenticando...
</template>
<script>

  import { mapActions } from 'vuex'

  export default {

    methods: {
      ...mapActions(['externalLogin']),

      decode() {

        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        var validation = urlParams.get('validation')

        this.externalLogin(validation)

      }
    },
    mounted(){
      this.decode()


    }
  }
</script>

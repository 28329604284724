<template lang="pug">
  v-container(fluid class="login-container")
    v-overlay(z-index)

    v-row(align="center" justify="center" style="height: 100%")

      v-col(xs="12" sm="10" md="4" lg="3" xl="3")

        v-card(class="cardBody")
          v-card-title(class="accent--text pt-2 pb-2")
            v-row
              v-col(class="text-center font-weight-bold primary--text text--darken-1") Login

          TopLoading

          v-card-text
            v-form(ref="form" lazy-validation v-model="valid" class="mt-4" v-on:submit.prevent='checkSystem')
              v-row(dense)
                v-col
                  v-text-field(outlined id="email" v-model="email" :rules="[rules.email, rules.required]" name="email" type="email" label="e-mail" data-vv-as="email" autocomplete="off")
              v-row(dense)
                v-col
                  v-text-field(outlined id="password" v-model="password" :rules="[rules.required]" name="password" ref="password" type="password" label="senha" data-vv-as="password" autocomplete="off" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'" @keyup.enter.native="submit")

            v-card-actions
              v-row(dense)
                v-col
                  v-btn(rounded @click="submit" depressed class="white--text" block color="primary" :loading="showBtnLoading") Entrar
                v-col
                  v-btn(rounded href="https://meu.ifraldas.com.br/forgot" target="_blank" block text class="text-lowercase accent--text") Esqueci a senha

      ErrorMessage
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import TopLoading from '@/components/core/TopLoading.vue'

import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3' // recaptcha no Login
Vue.use(VueReCaptcha, { siteKey: '6LfaK4IaAAAAAFyL_mC2cTeLgkcdpCOUVJn8QkOr' })

export default{
  components: {
    TopLoading
  },
  data(){
    return{
      value: true,
      valid: true,
      captchaToken: '',
      rules: {
        required: value => !!value || 'Campo obrigatório',
        email: v => !v || /.+@.+\..+/.test(v) || 'Digite um e-mail válido'
      },
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters(['showBtnLoading']),
    redirectToOldSystem(){
      return this.$store.state.auth.redirectToOldSystem
    }
  },
  methods: {
    ...mapActions(['userLogin']),

    async submit(){
      if(this.$refs.form.validate()){

        await this.$recaptchaLoaded()
        this.captchaToken = await this.$recaptcha('login')

        window.localStorage.removeItem('siteSlug')
        await this.userLogin({
          email: this.email.toLowerCase(),
          password: this.password,
          token: this.captchaToken+'HGHCkxRqKsP2TN4G'
        })
      }
    }
  },
  created() { }
}
</script>
<style scoped>

  .login-container{
    background: url('https://cdn.ifraldas.net/site/imgs/tela-login3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    height: 100%;
    width: 100%;
  }

  .cardBody{
    background-color: rgba(255, 255, 255, 0.82) !important;
    margin: auto !important;
    border-radius: 14px !important;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

</style>
